import React, {useCallback, useMemo} from 'react';
import classes from './Breadcrumbs.module.pcss';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
export type PageType = 'product' | 'training'

interface Props {
	title: string,
	parent: {
		name?: string,
		title?: string,
		uri: string
	},
	grandParent?: {
		name?: string,
		title?: string,
		uri: string
	},
	pageType: string
}

const Breadcrumbs = ({ title, parent, grandParent = null, pageType }: Props) => {
	const linkClickEventHandler = useCallback(async (value, location) => {
		await MixPanelWrapper.TrackEvent(
			'Breadcrumb Navigation Clicked',
			{
				value,
				location,
				category: pageType
			});
	}, [pageType]);

	const isParent = useMemo(() => parent?.title || parent?.name, [parent]);
	const isGrandParent = useMemo(() => grandParent?.title || grandParent?.name, [grandParent]);

	const parentTitle = useMemo(() => parse(parent?.title || parent?.name || ''), [parent]);
	const parentUri = useMemo(() => `/${parent?.uri}`, [parent]);

	const grandParentTitle = useMemo(() => parse(grandParent?.title || grandParent?.name || ''), [grandParent]);
	const grandParentUri = useMemo(() => `/${grandParent?.uri}`, [grandParent]);

	return (
		<div className={classes.Breadcrumbs}>
			<ul>
				<li><Link onClick={() => linkClickEventHandler('/homepage', 'Home page')}
						  to={'/homepage'}>Home page</Link></li>
				{isGrandParent &&
					<li><Link
						onClick={() => linkClickEventHandler(grandParentUri, grandParentTitle)}
						to={grandParentUri}>{grandParentTitle}</Link>
					</li>}
				{isParent &&
					<li><Link
						onClick={() => linkClickEventHandler(parentUri, parentTitle)}
						to={parentUri}>{parentTitle}</Link></li>}
				<li><span>{parse(title || '')}</span></li>
			</ul>
		</div>
	);
};

export default Breadcrumbs;
