import React, {useMemo} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import {CourseType} from '../Offerings';
import classes from './OfferingsCourse.module.pcss';
import ProductTabs from './ProductTabs/ProductTabs';
import CourseInfo from 'js/components/Templates/Training/Offerings/OfferingsCourse/CourseInfo/CourseInfo';
import OfferingsService from 'js/components/Templates/Training/Offerings/OfferingsService';

interface Props {
    activeCourse: CourseType,
    activeProductTab: string,
    tabClickHandler: Function
}

const OfferingsCourse = ({activeCourse, activeProductTab, tabClickHandler}: Props) => {
    const {courseTitle, productsTab} = useMemo(() => activeCourse, [activeCourse]) || {};
    const activeCourseInfo = useMemo(() =>
        OfferingsService.getActiveCourseInfo(activeProductTab, productsTab), [activeProductTab, productsTab]);
    return (
        <DataValidator data={activeCourse}>
            <section className={classes.OfferingsCourse}>
                <Heading text={courseTitle} type={'h4'} />
                <ProductTabs
                    products={productsTab}
                    activeProductTab={activeProductTab}
                    tabClickHandler={tabClickHandler}
                />
                <CourseInfo activeCourseInfo={activeCourseInfo} courseTitle={courseTitle}  />
            </section>
        </DataValidator>
    );
};

export default OfferingsCourse;
