import articleIcon from 'images/search/article-ico.jpg';
import moment from 'moment';

import {TabType} from './Tabs/Tabs';
import {
    ContentDataItemType,
    ContentDataType,
    EntityType,
    CommonContentItemType, InitContentDataType
} from './Items/DataTypes';
import SearchStore from 'js/store/SearchStore';
import parse from 'html-react-parser';

type ContentSectionDataType = {
    query: string,
    parent?: number,
    entity: string,
    id: number
}

class ProductContentService {
    tabsList:Array<TabType> = [
        {
            label: 'Videos',
            name: 'videosContent'
        },
        {
            label: 'Articles',
            name: 'articlesContent'
        },
        {
            label: 'Courses',
            name: 'coursesContent'
        },
        {
            label: 'Manuals',
            name: 'manualsContent'
        },
        {
            label: 'Release Updates',
            name: 'releaseUpdatesContent'
        }
    ];

    getTabList(fields: ContentDataType, defaultData: InitContentDataType) {
        return this?.tabsList?.filter((item) => {
            const el = fields?.[item?.name];
            const defaultEl:any = defaultData?.[item?.name];
            if (el?.customContent) {
                return el?.onFocusItem || el?.items?.length > 0;
            }
            return defaultEl?.items?.length > 0;
        });
    }
    getActiveContent(fields: ContentDataType, defaultData: InitContentDataType, activeTab) {
        if (!activeTab || !fields || !defaultData) {
            return {
                onFocusItem: null,
                items: [],
                description: ''
            };
        }
        const fieldsActiveTab:ContentDataItemType = fields[activeTab];
        let defaultAtaActiveTab:ContentDataItemType = defaultData[activeTab];

        if (activeTab === 'releaseUpdatesContent') {
            defaultAtaActiveTab = this.sortReleaseUpdateItemsByDate(defaultAtaActiveTab);
        }

        if (fieldsActiveTab?.customContent) {
            return {
                onFocusItem: fieldsActiveTab?.onFocusItem,
                items: fieldsActiveTab?.items,
                description: fieldsActiveTab?.description
            };
        }
        const {items} = defaultAtaActiveTab || {};
        if (!items?.length) {
            return {
                onFocusItem: null,
                items: [],
                description: ''
            };
        }
        const onFocusItem = items[0];
        const otherItems = items?.slice(1);

        return {
            onFocusItem,
            items: otherItems,
            description: fieldsActiveTab?.description
        };
    }

    getItemFieldsByType(item: EntityType):CommonContentItemType {
        if (!item) {
            return null;
        }
        const defaultData = {
            title: item?.title,
            databaseId: item?.databaseId,
            link: item?.link,
            uri: item?.uri
        };

        const getImage = (image: any): string => typeof image === 'string' ? image : '';

        if ('Articles' in item) {
            return {
                ...defaultData,
                type: 'article',
                description: item?.Articles?.shortDescription,
                image: getImage(articleIcon),
                buttonLabel: 'Read Now'
            };
        } else if ('Videos' in item) {
            return {
                ...defaultData,
                type: 'video',
                description: item?.Videos?.descriptionVideo,
                image: item?.Videos?.attachedVideo?.videoPreview?.mediaItemUrl,
                buttonLabel: 'Watch Now'
            };
        } else if ('Manuals' in item) {
            return {
                ...defaultData,
                type: 'manual',
                description: item?.Manuals?.descriptionManual,
                image: item?.Manuals?.manualThumbnail?.mediaItemUrl,
                buttonLabel: 'Download'
            };
        } else if ('Courses' in item) {
            return {
                ...defaultData,
                type: 'course',
                description: item?.Courses?.courseDescription,
                image: item?.featuredImage?.mediaItemUrl,
                buttonLabel: 'Start Course'
            };
        } else {
            return null;
        }
    }

    sortReleaseUpdateItemsByDate(data: ContentDataItemType):ContentDataItemType {
        data.items.sort((a:any, b:any) => {
            const dateA:any = moment(a.Articles?.date || a.Manuals?.date, a.Articles ? 'YYYY-MM-DD' : 'DD/MM/YYYY');
            const dateB:any = moment(b.Articles?.date || b.Manuals?.date, b.Articles ? 'YYYY-MM-DD' : 'DD/MM/YYYY');
            return dateB - dateA;
        });
        data.items = data?.items?.slice(0, 3);
        return data;
    }

    viewMoreButtonClickHandler(data: ContentSectionDataType) {
        SearchStore.setSearchTransferData(data);
    }

    getMixPanelEventData(name, value, parent) {
        let eventData:{
            category: string,
            value: string,
            productName?: string,
            subjectMaterName?: string
        } = {
            productName: `${parse(name || '')}`,
            value,
            category: 'Product page'
        };

        if (parent) {
            delete eventData.productName;
            eventData = {
                subjectMaterName: `${parse(name || '')}`,
                value,
                category: 'Subject Mater page'
            };
        }

        return eventData;
    }

    getMixPanelEntityData(val) {
        let entity = '';

        switch (val) {
            case 'articlesContent' : entity = 'Articles';
                break;
            case 'coursesContent' : entity = 'Courses';
                break;
            case 'videosContent' : entity = 'Videos';
                break;
            case 'manualsContent' : entity = 'Manuals';
                break;
            case 'releaseUpdatesContent' : entity = 'Release Updates';
                break;
            default : entity = '';
        }

        return entity;
    }

}

export default new ProductContentService();
