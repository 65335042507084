import React, {useCallback, useMemo} from 'react';
import parse from 'html-react-parser';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';

import classes from '../Items.module.pcss';
import {CommonContentItemType} from '../DataTypes';
import {downloadManualFetchData} from '../../fetchData';
import ProductLabelsList from 'js/components/Common/ProductLabelList/ProductLabelsList';
import ProductContentService from 'js/components/Taxonomies/ProductContent/ProductContentService';

interface Props {
    pageTitle: string,
    activeItem: CommonContentItemType,
    isFocus?: boolean,
    parent?: number
}

const Item = ({pageTitle, activeItem, isFocus = false, parent}: Props) => {
    const {databaseId, link, title, uri, type, buttonLabel, description, image} = activeItem || {};

    const getEventData = useCallback(value =>
        ProductContentService.getMixPanelEventData(pageTitle, value, parent)
        , [pageTitle, parent]);

    const itemEventClickHandler = useCallback(async (val: string) => {
        await MixPanelWrapper.TrackEvent(
            'CTA button is clicked on. Content section',
            getEventData(val)
        );
    }, [getEventData]);

    const fileDownloadHandler = useFetchCallback({
        updateData: data => data?.manualsSingle?.updateDownloads,
        query: () => `${downloadManualFetchData(databaseId)}`,
        onStart: () => {
            const element = document.createElement('a');
            element.setAttribute('href', link);
            const ext = link.split('.').pop();
            element.setAttribute('download', `${title}.${ext}`);

            element.style.display = 'block';
            element.click();
        },
        onSuccess: async () => {
            await MixPanelWrapper.TrackEvent(
                'Download Manual',
                getEventData(title)
            );
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });

    const button = useMemo(() => {
        if (type === 'manual') {
            return <Button
                additionalStylesClass={classes.Button}
                type={'button'}
                handler={fileDownloadHandler}
                text={isFocus ? buttonLabel : ''} />;
        }
        return <Button
            additionalStylesClass={classes.Button}
            type={'a'}
            handler={() => itemEventClickHandler(title)}
            buttonType={type === 'article' ? 'ArticleButton' : 'ButtonColored'}
            link={`/${uri}`}
            text={isFocus ? buttonLabel : ''} />;
    }, [title, itemEventClickHandler, isFocus, buttonLabel, uri, type, fileDownloadHandler]);

    return (
        <div key={activeItem?.databaseId} className={`${classes.Item} ${isFocus && classes.OnFocusItem}`}>
            <div className={classes.Top}>
                <div className={classes.Image}>
                    <Image mediaItemUrl={image} alt={title}/>
                </div>
                <div className={classes.Info}>
                    <Heading text={title} type={'h4'}/>
                    <ProductLabelsList pageId={activeItem?.databaseId} />
                    <div className={classes.Description}>{parse(description || '')}</div>
                </div>
            </div>
            {button}
        </div>
    );
};

export default Item;
