import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData, {FetchResultType, getFetchResult} from './fetchData';
import classes from './TopSection.module.pcss';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import SearchStore from 'js/store/SearchStore';

export type PageType = 'product' | 'page' | 'search'
interface Props {
	pageId: number,
	pageType: PageType
}
const TopSection = ({ pageId, pageType }: Props) => {
	const {searchQuery, sourcePage} = SearchStore;
	const [title, setTitle] = useState<string>('');
	const [template, setTemplate] = useState<string>('');
	const [mixPanelCategory, setMixPanelCategory] = useState<string>('');
	const [parent, setParent] = useState<{
		uri: string,
		name?: string,
		title?: string
	}>({
		name: '',
		title: '',
		uri: ''
	});
	const [grandParent, setGrandParent] = useState<{
		uri: string,
		name?: string,
		title?: string
	}>({
		name: '',
		title: '',
		uri: ''
	});
	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageType, pageId)}`,
	    condition: !!pageId,
	    updateData: (data: any):void => {
			const result:FetchResultType = getFetchResult(data, pageType);
			setTitle(result?.title);
			setParent(result?.parent);
			setTemplate(result?.template?.templateName);
		}
	});

	useEffect(() => {
		if (pageType !== 'search') {
			return;
		}
		setTitle('Search Results');

		if (sourcePage?.uri === 'homepage' || sourcePage?.uri === 'search') {
			return;
		}

		setParent({
			name: sourcePage?.name,
			uri: sourcePage?.uri
		});
		setGrandParent({
			name: sourcePage?.parent?.name,
			uri: sourcePage?.parent?.uri
		});

	}, [sourcePage, pageType]);

	useEffect(() => {
		if (pageType === 'product') {
			if (parent?.name?.length > 0) {
				setMixPanelCategory('Subject Matter page');
				return;
			}
			setMixPanelCategory('Product page');
			return;
		}

		if (pageType === 'search') {
			setMixPanelCategory('Search Results');
			return;
		}

		if (pageType === 'page') {
			setMixPanelCategory(`${template} page`);
			return;
		}
	}, [parent, pageType, template]);

	return (
		<DataValidator data={!isLoading && !error && title}>
			<section className={`${classes.TopSection} ${pageType === 'search' ? classes.SearchType : ''}`}>
				<Container>
					{pageType === 'search' ? <p className={classes.TopSectionSubtitle}>Showing search results for: </p> : ''}
					<Heading text={pageType === 'search' ? searchQuery : title} type={'h1'}/>
					<Breadcrumbs
						title={title}
						parent={parent}
						grandParent={grandParent}
						pageType={mixPanelCategory} />
				</Container>
			</section>
		</DataValidator>
	);
};

export default observer(TopSection);
