import SearchStore from 'js/store/SearchStore';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

class SearchFilterService {
    async filterItemClickHandler(e, taxonomyName, anchor, type, heading) {
        const { filterEntitiesData, filterTaxData, searchQuery } = SearchStore;
        const { checked } = e?.target;
        const targetName = taxonomyName === 'productLegacy' ? e?.target?.value : e?.target?.name;

        if (searchQuery) {
            SearchStore.setSearchStatus(false);
            SearchStore.setSearchPage(1);
        }

        if (checked) {
            await MixPanelWrapper.TrackEvent('Search Filtering', {
                category: 'Search Filters',
                type: heading,
                value: e?.target?.dataset?.label
            });
        }

        anchor();

        if (type === 'entities') {
            const entitiesArray = filterEntitiesData ? filterEntitiesData.split(',') : [];
            const updatedEntitiesData = checked
                ? [...entitiesArray, targetName]
                : entitiesArray.filter(entityName => entityName !== targetName);

            SearchStore.setFilterEntitiesData(updatedEntitiesData.join(','));
            return;
        }
        const filterTaxDataCopy = { ...filterTaxData };
        if (taxonomyName === 'productLegacy') {
            filterTaxDataCopy[taxonomyName] = targetName;
        } else {
            let currentTaxData = [];
            if (filterTaxDataCopy[taxonomyName]?.length > 0) {
                currentTaxData = filterTaxDataCopy[taxonomyName]?.split(',') || [];
            }
            if (checked) {
                filterTaxDataCopy[taxonomyName] = [...currentTaxData, targetName].join(',');
            } else {
                filterTaxDataCopy[taxonomyName] = currentTaxData.filter(name => name !== targetName).join(',');
            }
        }

        SearchStore.setFilterTaxData(filterTaxDataCopy);
    }

    quickSort(arr) {
        if (arr.length < 2) return arr;

        const pivot = arr[0];
        const left = [];
        const right = [];

        for (let i = 1; i < arr.length; i++) {
            if (arr[i]?.count > pivot?.count) {
                left.push(arr[i]);
            } else {
                right.push(arr[i]);
            }
        }

        return [...this.quickSort(left), pivot, ...this.quickSort(right)];
    }

    removeItemFromState(taxonomyName, itemId, type) {
        if (SearchStore.isSearchTransferData) {
            return;
        }

        if (type === 'taxonomy') {
            const currentTaxData = SearchStore.filterTaxData[taxonomyName];
            if (currentTaxData?.split(',')?.some(id => parseInt(id) === parseInt(itemId))) {
                SearchStore.setFilterTaxData({ ...SearchStore.filterTaxData, [taxonomyName]: '' });
            }
            return;
        }

        const entitiesStr = SearchStore.filterEntitiesData;
        if (!entitiesStr) return;

        const updatedEntitiesData = entitiesStr?.split(',')
            .filter(entityName => SearchStore.entitiesCounts?.some(el => el?.name === entityName && el?.count));

        SearchStore.setFilterEntitiesData(updatedEntitiesData.join(','));
    }
}

export default new SearchFilterService();
