import React, {useMemo} from 'react';

import Item from './Item/Item';
import classes from './Items.module.pcss';
import Description from '../Description/Description';
import ProductContentService from '../ProductContentService';

const Items = ({data, parent, pageTitle}) => {
    const {onFocusItem, items, description} = useMemo(() => data, [data]) || {};
    const focus = useMemo(() => ProductContentService.getItemFieldsByType(onFocusItem), [onFocusItem]);

    return (
        <div className={classes.Items}>
            <Description value={description}/>
            <div className={classes.Wrap}>
                <Item pageTitle={pageTitle} parent={parent} activeItem={focus} isFocus={true}/>
                <div className={classes.Other}>
                    {items?.map((item, index) =>
                        <Item
                            key={index}
                            activeItem={ProductContentService.getItemFieldsByType(item)}
                            pageTitle={pageTitle}/>)}
                </div>
            </div>
        </div>
    );
};

export default Items;
