import React, {useCallback, useMemo} from 'react';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import {observer} from 'mobx-react';

import classes from './SearchSorting.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import SearchStore from 'js/store/SearchStore';

const SearchSorting = () => {
    const {orderBy, searchStatus, searchQuery} = SearchStore;

    const options = useMemo(() => [
        { value: 'mostRelevant', label: 'Most Relevant' },
        { value: 'type', label: 'Type'},
        { value: 'newest', label: 'Newest'},
        { value: 'views', label: 'Most Views'}
    ], []);

    const sendMixPanelEvent = useCallback(async (value: any) => {
        await MixPanelWrapper.TrackEvent(
            'Search Sorting',
            {
                category: 'Search Filters',
                value: `${value}`
            });
    }, []);

    return (
        <div data-search-sorting={true} className={`${classes.Sorting} ${searchStatus || searchQuery.length === 0 ? classes.SortingActive : ''}`}>
            <Dropdown
                className={classes.SortingDropdown}
                controlClassName={classes.SortingDropdownControl}
                arrowClassName={classes.SortingDropdownArrow}
                menuClassName={classes.SortingDropdownMenu}
                options={options}
                onChange={async (e) => {
                    if (orderBy === e?.value) {
                        return;
                    }
                    SearchStore.setOrderBy(e?.value);
                    if (searchQuery) {
                        SearchStore.setSearchStatus(false);
                    }
                    await sendMixPanelEvent(e?.label);
                }}
                value={orderBy} placeholder={orderBy} />
        </div>
    );
};

export default observer(SearchSorting);
