import React, {useMemo, useRef} from 'react';
import Button from 'js/components/Common/Button/Button';
import Heading from 'js/components/Common/Heading/Heading';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import {SubTopicDataType} from '../SubTopic';
import SubTopicService from '../SubTopicService';
import classes from './SubTopicData.module.pcss';
import SubTopicColumns from './SubTopicColumns/SubTopicColumns';


interface Props {
	title: string,
    isLoading: boolean,
    visibleArticles: number,
    showMoreHandler: any,
    maxPages: number,
    currentPage: number,
    productName: string,
    activeItem: {
        name: string,
        id: number
    },
    articles: Array<SubTopicDataType>
}

const SubTopicData = (
    {
        title,
        activeItem,
        articles,
        isLoading,
        visibleArticles,
        showMoreHandler,
        currentPage,
        maxPages,
        productName
    }: Props) => {
    const nodeRef = useRef(null);

    const { column1, column2 } = useMemo(() =>
        SubTopicService.separateArticleIntoColumns(articles?.slice(0, visibleArticles)),
        [articles, visibleArticles]);

    return (
        <DataValidator data={articles?.length}>
            <section className={classes.SubTopicData} ref={nodeRef}>
                <Heading text={title} type={'h3'} />
                <div className={`${classes.SubTopicDataItems} ${isLoading ? classes.Loading : ''}`}>
                    <SubTopicColumns
                        activeItem={activeItem}
                        productName={productName}
                        column1={column1}
                        column2={column2}
                        nodeRef={nodeRef}/>
                </div>
                {maxPages !== currentPage && (
                    <Button type={'button'}
                            buttonColorType={'Pink'}
                            handler={showMoreHandler}
                            text={'Load More'} />
                )}
            </section>
        </DataValidator>
    );
};

export default SubTopicData;
