import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Route, useLocation} from 'react-router';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import {CommonContext} from 'js/context/CommonContext/CommonContext';
import EventsTemplate from 'js/containers/PageTemplates/EventsTemplate';
import PolicyTemplate from 'js/containers/PageTemplates/PolicyTemplate';
import DefaultTemplate from 'js/containers/PageTemplates/DefaultTemplate';
import CoursesTemplate from 'js/containers/PageTemplates/CoursesTemplate';
import TrainingTemplate from 'js/containers/PageTemplates/TrainingTemplate';
import HelpPageTemplate from 'js/containers/PageTemplates/HelpPageTemplate';
import ContactUsTemplate from 'js/containers/PageTemplates/ContactUsTemplate';
import ManualsHubTemplate from 'js/containers/PageTemplates/ManualsHubTemplate';
import OnboardingTemplate from 'js/containers/PageTemplates/OnboardingTemplate';
import AboutUsPageTemplate from 'js/containers/PageTemplates/AboutUsPageTemplate';
import PreLoginPageTemplate from 'js/containers/PageTemplates/PreLoginPageTemplate';
import PostLoginPageTemplate from 'js/containers/PageTemplates/PostLoginPageTemplate';
import CoursesCatalogueTemplate from 'js/containers/PageTemplates/CoursesCatalogueTemplate';

import {TemplateRouterDataInterface} from './TemplateRouterDataInterface';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import isEmptyObject from 'js/helpers/isEmptyObject';
import SearchStore from 'js/store/SearchStore';

const useTemplateRouter = () => {
    const location = useLocation();
    const [page, setPage] = useState<TemplateRouterDataInterface>({});
    const [pages, setPages] = useState<TemplateRouterDataInterface[]>([]);
    const {isCustomerLogged, apiPath} = useContext(CommonContext);

    const pageItem = useMemo(() => pages?.find((item) => {
        const path: string = location?.pathname;
        const {template, uri} = item || {};
        const {templateName} = template || {};
        const slug = templateName === 'PreLogin Page' ? '' : uri;
        return `/${slug}` === path;
    }), [pages, location]);

    const currentPage = useMemo(() => {
        if (!pageItem) {
            return null;
        }
        const {templateName} = pageItem?.template || {};
        pageItem.uri = templateName === 'PreLogin Page' ? '' : pageItem?.uri;
        return pageItem;
    }, [pageItem]);

    useFetchData({
        condition: isCustomerLogged !== null,
        path: `${apiPath}/wp-content/uploads/page_route_file.json`,
        responseVar: '',
        updateData: (data:TemplateRouterDataInterface[]) => setPages(data)
    });

    useEffect(() => {
        if (!currentPage) {
            return;
        }
        setPage({...currentPage});
    }, [currentPage, pages]);

    useEffect(() => {
        if (!page) {
            return;
        }
        SearchStore.setSourcePage({
            name: page?.title,
            uri: page?.uri,
            parent: null
        });
    }, [page]);

    const template = useMemo(() => {
        const name = page?.template?.templateName;
        const id = page?.pageId;
        switch (name) {
            case 'Policy' :             return <PolicyTemplate key={id} pageId={id}/>;
            case 'Courses' :            return <CoursesTemplate key={id} pageId={id}/>;
            case 'Default' :            return <DefaultTemplate key={id} pageId={id}/>;
            case 'Contact Us' :         return <ContactUsTemplate key={id} pageId={id}/>;
            case 'Onboarding' :         return <OnboardingTemplate key={id} pageId={id}/>;
            case 'About Us' :           return <AboutUsPageTemplate key={id} pageId={id}/>;
            case 'Help' :               return <HelpPageTemplate key={id} pageId={id}/>;
            case 'Events' :             return isCustomerLogged === true && <EventsTemplate key={id} pageId={id}/>;
            case 'Training' :           return isCustomerLogged === true && <TrainingTemplate key={id} pageId={id}/>;
            case 'Manuals Hub' :        return isCustomerLogged === true && <ManualsHubTemplate key={id} pageId={id}/>;
            case 'PostLogin Page' :     return isCustomerLogged === true && <PostLoginPageTemplate key={id} pageId={id}/>;
            case 'PreLogin Page' :      return isCustomerLogged === false && <PreLoginPageTemplate key={id} pageId={id}/>;
            case 'Courses Catalogue' :  return isCustomerLogged === true && <CoursesCatalogueTemplate key={id} pageId={id}/>;
            default : return null;
        }
    }, [isCustomerLogged, page]);

    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);

    useEffect(() => {
        if (!isEmptyObject(page)) {
            return;
        }
        (async () =>
            await MixPanelWrapper.TrackPageView({
            page: page?.title
        }))();
    }, [page, INIT_MIX_PANEL]);

    return (
        <Route exact path={`/${page?.uri}`} render={
            () => (
                <>
                    <SeoHelmet
                        canonical={`/${page?.uri}`}
                        title={page?.SeoMetadata?.metaTitle || page?.title}
                        description={page?.SeoMetadata?.metaDescription || ''}
                    />
                    { template }
                </>
            )
        }/>
    );
};

export default useTemplateRouter;
