import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom';
import { Navigation, Pagination } from 'swiper/js/swiper.esm';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import fetchData from './fetchData';
import parse from 'html-react-parser';
import slides from './helpers';
import classes from './SubjectMatterList.module.pcss';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface Props {
	pageId: number
}
export type SubjectMatterListType = {
	name: string,
	uri: string,
	id: string
}
const SubjectMatterList = ({ pageId }: Props) => {
	const [swiperInstance, setSwiperInstance] = useState(null);
	const swiperRef = useRef(null);
	const [title, setTitle] = useState<string>('');
	const [productTitle, setProductTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [itemsPerSlide, setItemsPerSlide] = useState<number>(18);
	const [subjectMatterList, setSubjectMatterList] = useState<Array<SubjectMatterListType>>(null);

	const { isLoading, error } = useFetchData({
	    query: `${fetchData(pageId)}`,
	    condition: !!pageId,
	    updateData: (data: any):void => {
			setProductTitle(data?.productLegacy?.name);
			setTitle(data?.product?.ProductGlobal?.subjectMatterTitleProduct);
			setDescription(data?.productLegacy?.ProductLegacyFields?.subjectMatterDescription);
			setItemsPerSlide(data?.productLegacy?.ProductLegacyFields?.subjectMatterItemsPerSlide);
			setSubjectMatterList(data?.productLegacyList?.nodes?.reverse());
		}
	});

	const params = {
		slidesPerView: 1,
		noSwiping: true,
		speed: 500,
		autoHeight: true,
		watchOverflow: true,
		direction: 'horizontal',
		shouldSwiperUpdate: true,
		watchSlidesVisibility: true,
		containerModifierClass: 'swiper-container-wrap ',
		spaceBetween: 16,
		modules: [Navigation, Pagination],
		pagination: {
			el: `.${classes.Slider} + .${classes.Bullets}`,
			clickable: true
		},
		on: {
			slideChangeTransitionStart() {
				this.updateAutoHeight();
			}
		},
		getSwiper: setSwiperInstance
	};

	const list:SubjectMatterListType[][] = useMemo(() =>
		slides(subjectMatterList, itemsPerSlide), [subjectMatterList, itemsPerSlide]);


	useEffect(() => {
		if (!swiperInstance) {
			return;
		}
		const paginationBullets = swiperInstance?.pagination?.bullets;
		if (!paginationBullets) {
			return;
		}
		Object?.keys(paginationBullets)?.forEach((key) => {
			if (key !== 'length') {
				const bullet = paginationBullets[key];
				bullet?.addEventListener('click', async () => {
					await MixPanelWrapper.TrackEvent(
						'Slider Component is clicked on',
						{
							productName: `${parse(productTitle || '')}`,
							category: 'Product page'
						});
				});
			}
		});
	}, [productTitle, swiperInstance]);

	const linkClickEventHandler = useCallback(async (value) => {
		await MixPanelWrapper.TrackEvent(
			'Subject Matter button click',
			{
				productName: `${parse(productTitle || '')}`,
				value,
				category: 'Product page'
			});
	}, [productTitle]);

	return (
		<DataValidator data={!isLoading && !error && title && description && subjectMatterList}>
			<section className={classes.SubjectMatterList}>
				<Container additionalClass={classes.Wrap}>
					<Heading text={title} type={'h2'}/>
					<div className={classes.Info}>
						{parse(description || '', {trim: true})}
					</div>
					<div className={classes.SliderWrap}>
						<div ref={swiperRef} className={classes.Slider}>
							<Swiper {...params}>
								{list?.map((slide, index) => (
									<div className={`${classes.Slide} swiper-slide`} key={index}>
										{slide?.map((item: { id: React.Key; uri: any; name: any; }) => {
											const label = parse(item?.name || '');
											return (
												<div className={classes.Item} key={item.id}>
													<Link onClick={() => linkClickEventHandler(label)}
														  to={`/${item.uri}`}>
														<span>{label}</span>
														<span>{label}</span>
													</Link>
												</div>
											);
										})}
									</div>
								))}
							</Swiper>
						</div>
						<div className={`${classes.Bullets}`}></div>
					</div>
				</Container>
			</section>
		</DataValidator>
	);
};

export default SubjectMatterList;
