import React, {useCallback, useMemo} from 'react';

import classes from './SubTopicColumn.module.pcss';
import Button from 'js/components/Common/Button/Button';
import {SubTopicDataType} from 'js/components/Taxonomies/SubTopic/SubTopic';
import SubTopicService from 'js/components/Taxonomies/SubTopic/SubTopicService';
import useFetchCallback from 'js/hooks/useFetchCallback/useFetchCallback';
import ExpandableSection from 'js/components/Common/ExpandableSection/ExpandableSection';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface Props {
    item: SubTopicDataType,
    nodeRef:any,
    isLast: boolean,
    productName: string,
    activeItem: {
        name: string,
        id: number
    }
}

const SubTopicColumn = ({item, nodeRef, isLast, productName, activeItem}: Props) => {
    const mixPanelEventData = useMemo(() => ({
            category: 'Subject Matter page',
            subjectMaterName: productName,
            value: item?.title,
            selected: activeItem?.name
        }), [item, activeItem, productName]);

    const itemEventClickHandler = useCallback(async () => {
        await MixPanelWrapper.TrackEvent(
            '\'All About...\' section CTA button is clicked',
            mixPanelEventData
        );
    }, [mixPanelEventData]);

    const queryByType = useMemo(() => {
        if (item?.type === 'manuals') {
            return `{
                manualsSingle(id: "${item?.id}", idType: DATABASE_ID) {
                    updateDownloads
                }
                }`;
        } else {
            return `{
                presentationsSingle(id: "${item?.id}", idType: DATABASE_ID) {
                  updateDownloads
                }
            }`;
        }
    }, [item]);
    const fileDownloadHandler = useFetchCallback({
        updateData: data => data?.manualsSingle?.updateDownloads,
        query: () => `${queryByType}`,
        onStart: () => {
            const element = document.createElement('a');
            element.setAttribute('href', item?.link);
            const ext = item?.link.split('.').pop();
            element.setAttribute('download', `${item?.title}.${ext}`);

            element.style.display = 'block';
            element.click();
        },
        onSuccess: async () => {
            await MixPanelWrapper.TrackEvent(
                'Download Manual',
                mixPanelEventData
            );
        },
        onFailure: () => {
            console.error('Error while downloading');
        },
        onError: (e) => {
            console.error(e);
        }
    });

    const button = useMemo(() => {
        if (item?.type === 'manuals' || item?.type === 'presentations') {
            return <Button
                type={'button'}
                handler={fileDownloadHandler}
                text={SubTopicService.getButtonLabelByType(item?.type)} />;
        }
        return <Button
            type={'a'}
            link={item?.link}
            handler={() => itemEventClickHandler()}
            text={SubTopicService.getButtonLabelByType(item?.type)} />;
    }, [itemEventClickHandler, fileDownloadHandler, item]);

    return (
        <div key={item?.id} className={classes.SubTopicColumnItem}>
            <ExpandableSection
                title={item?.title}
                info={
                    <>
                        <p>{item?.description}</p>
                        {button}
                    </>
                }
                withoutLine={isLast}
                withTypeLabel={true}
                entityType={item?.type}
                nodeRef={nodeRef} />
        </div>
    );
};

export default SubTopicColumn;
