import React, {useCallback, useContext, useEffect, useState} from 'react';
import parse from 'html-react-parser';
import { NavLink, useLocation } from 'react-router-dom';
import { CommonContext } from 'js/context/CommonContext/CommonContext';

import classes from './NavigationItem.module.pcss';
import NavigationItemPopUp from './NavigationItemPopUp/NavigationItemPopUp';
import {NavigationMenuItemType} from '../NavigationMenu/NavigationMenuDataInterface';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';


export interface Props {
	key?: number,
	item?: NavigationMenuItemType,
	hasSubMenuClass?: boolean,
	setActiveMenuItem?: Function,
	activeMenuItem?: number,
	subMenu?: any,
	location?: string,
	children: any
}

const NavigationItem = (props:Props) => {
	let link = props?.item?.url;
	const location = useLocation();
	const {subMenu} = props || null;
	const comingSoon = props?.item?.ComingSoon?.comingSoonLabel;
	const hasSubMenuClass = props?.hasSubMenuClass ? 'hasSubMenuClass' : '';
	const [activeItem, setActiveItem] = useState<'active' | ''>('');
	const {apiPath, setActiveMenuItemId, activeMenuItemId} = useContext(CommonContext);
	const [activeParentItem, setActiveParentItem] = useState<'activeParent' | ''>('');
	const {
		usePopup,
		popupInfo,
		popupTitle,
		buttonTextFooter,
		useButtonFooter,
		popupTypeFooter
	} = props?.item?.FooterMenu || {};

	const setParentActive = useCallback(() => {
		const parent = props?.subMenu?.props?.menuItems;
		if (!parent) {
			return;
		}
		const activeParent = parent.find(item => item.menuItemId === activeMenuItemId);
		activeParent ? setActiveParentItem('activeParent') : setActiveParentItem('');
	}, [props, activeMenuItemId]);

	const menuItemEventHandler = useCallback(async () => {
		if (!props?.item) {
			return;
		}
		return await MixPanelWrapper.TrackEvent(
			'Navigation Item is Clicked On',
			{
				value: props?.item?.label,
				name: props?.location,
				category: 'Menu'
			}
		);
	}, [props]);

	useEffect(() => {
		const fullUrl = props?.item?.url;
		const itemId = props?.item?.menuItemId;

		if (fullUrl.includes(apiPath)) {
			const cutLink = fullUrl.replace(apiPath, '');

			if (cutLink === location.pathname) {
				setActiveItem('active');
				setActiveMenuItemId(itemId);
			} else {
				setActiveItem('');
			}
		}
		setParentActive();
	}, [apiPath, props, location.pathname, setParentActive, subMenu, activeMenuItemId, setActiveMenuItemId]);

	if (comingSoon) {
		return (
			<li className={`${classes.NavigationItem}`}>
				<span className={classes.ComingSoonLabel}>Coming Soon</span>
				<span className={`${classes.emptyLink} emptyLink`}>{parse(props.children || '')}</span>
			</li>
		);
	}

	if (usePopup && popupInfo) {
		return (
			<li className={`${classes.NavigationItem} ${hasSubMenuClass} ${activeParentItem} ${activeItem}`}>
				<NavigationItemPopUp
					link={link}
					title={popupTitle}
					label={props.children}
					type={popupTypeFooter}
					buttonText={useButtonFooter && buttonTextFooter}
					popupInfo={popupInfo}/>
				{subMenu}
			</li>
		);
	}

	if (link.includes(apiPath)) {
		link = link.replace(apiPath, '');

		if (link !== location.pathname) {
			return (
				<li className={`${classes.NavigationItem} ${hasSubMenuClass} ${activeParentItem} ${activeItem}`}>
					<NavLink
						onClick={async () => await menuItemEventHandler()}
						exact={true}
						to={link}>
						{parse(props.children || '')}
					</NavLink>
					{subMenu}
				</li>
			);
		}

		return (
			<li className={`${classes.NavigationItem} ${hasSubMenuClass} ${activeParentItem} ${activeItem}`}>
				<span className={`${classes.emptyLink} emptyLink`}>{parse(props.children || '')}</span>
				{subMenu}
			</li>
		);
	}

	if (link === '#') {
		return (
			<li className={`${classes.NavigationItem} ${hasSubMenuClass} ${activeParentItem} ${activeItem}`}>
				<span className={`${classes.emptyLink} emptyLink`}>{parse(props.children || '')}</span>
				{subMenu}
			</li>
		);
	}

	return (
		<li className={`${classes.NavigationItem} ${hasSubMenuClass} ${activeParentItem} ${activeItem}`}>
			<a onClick={() => menuItemEventHandler()} href={link} target="_blank" rel="nofollow noopener noreferrer">{parse(props.children || '')}</a>
			{subMenu}
		</li>
	);
};

export default NavigationItem;
