import React, {useCallback} from 'react';
import parse from 'html-react-parser';
import Icon from 'images/getting-started-img.svg';
import Image from 'js/components/Common/Image/Image';
import Button from 'js/components/Common/Button/Button';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';

import classes from './GettingStartedItems.module.pcss';
import GettingStartedService from '../GettingStartedService';

export type GettingStartedItemType = {
	id: number,
	title: string,
	date: string,
	link: string,
	Articles: {
		shortDescription: string
	}
}

interface Props {
	data: Array<GettingStartedItemType>,
	smName: string
}

const GettingStartedItems = ({data, smName}: Props) => {
	const buttonClickEventHandler = useCallback(async (title) => {
		await MixPanelWrapper.TrackEvent(
			'\'Getting Started\' section CTA button is clicked on',
			{
				category: 'Subject Matter page',
				subjectMaterName: smName,
				value: title
			}
		);
	}, [smName]);
	return (
		<div className={classes.GettingStartedItems}>
			{data?.map((item, key) => (
				<div key={item?.id}
					 className={`${classes.GettingStartedItem} ${GettingStartedService.getWideClass(data?.length, key)}`}>
					<div className={classes.Top}>
						<figure>
							<Image mediaItemUrl={Icon} alt={'article'}/>
						</figure>
						<Heading text={item?.title} type={'h4'}/>
					</div>
					<div className={classes.Description}>{parse(item?.Articles?.shortDescription || '')}</div>
					<Button handler={() => buttonClickEventHandler(item?.title)} link={item?.link} type={'a'} buttonType={'ArticleButton'} text={'Read Now'}/>
				</div>
			))}
		</div>
	);
};

export default GettingStartedItems;
