export default (type:string, uri: string) => `{
   ${type}(id: "${uri}", idType: URI) {
          databaseId
          name
          link
          uri
          slug
          parent {
            name
            uri
          }
          SeoMetadata {
            metaTitle
            metaDescription
          }
        }
    } `;
