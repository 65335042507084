import {SubjectMatterListType} from './SubjectMatterList';

const slides =
    (subjectMatterList: Array<SubjectMatterListType>, count: number):Array<Array<SubjectMatterListType>>  => {
    if (!subjectMatterList) return [];
    const slidesArray = [];
    for (let i = 0; i < subjectMatterList?.length; i += count) {
        slidesArray.push(subjectMatterList?.slice(i, i + count));
    }
    return slidesArray;
};
export default slides;
