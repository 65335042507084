import {Type} from './FAQ';

export default (pageId: number, type: Type, fieldGroupName: string) =>  {
    const productTitleQuery:string = `
        product {
          ProductGlobal {
            faqTitleProduct
          }
        }
    `;
    const subjectMatterTitleQuery:string = `
        subjectMatter {
          SubjectMatterGlobal {
            faqTitleSm
          }
        }
    `;
    if (type === 'Product' || type === 'SubjectMatter') {
        return `{
        productLegacy(id: "${pageId}", idType: DATABASE_ID) {
          name
          ProductLegacyFields {
            faqProductLegacy {
              caseHeader
              caseBody
            }
          }
        }
        ${type === 'Product' ? productTitleQuery : subjectMatterTitleQuery}
        }`;
    }

    if (type === 'Page') {
        return `{
            page(id: "${pageId}", idType: DATABASE_ID) {
              title
              template {
                ... on DefaultTemplate {
                  templateName
                }
                ... on AboutUsTemplate {
                  templateName
                }
                ... on ContactUsTemplate {
                  templateName
                }
                ... on CoursesCatalogueTemplate {
                  templateName
                }
                ... on CoursesTemplate {
                  templateName
                }
                ... on EventsTemplate {
                  templateName
                }
                ... on HelpTemplate {
                  templateName
                }
                ... on ManualsHubTemplate {
                  templateName
                }
                ... on OnboardingTemplate {
                  templateName
                }
                ... on PolicyTemplate {
                  templateName
                }
                ... on PostLoginPageTemplate {
                  templateName
                }
                ... on PreLoginPageTemplate {
                  templateName
                }
                ... on TrainingTemplate {
                  templateName
                }
                }
              ${fieldGroupName} {
                titleFaq
                faq {
                  caseHeader
                  caseBody
                }
              }
            }
          }`;
    }

};
