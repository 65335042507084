import React from 'react';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';

import classes from './SubTopicColumns.module.pcss';
import {SubTopicDataType} from 'js/components/Taxonomies/SubTopic/SubTopic';
import SubTopicColumn from './SubTopicColumn/SubTopicColumn';

interface Props {
    column1:Array<SubTopicDataType>,
    column2:Array<SubTopicDataType>,
    nodeRef:any,
    productName: string,
    activeItem: {
        name: string,
        id: number
    }
}

const SubTopicColumns = ({column1, column2, nodeRef, productName, activeItem}: Props) => (
    <DataValidator data={column1?.length || column2?.length}>
        {column1?.length > 0 ?
            <div className={classes.Column}>
                {column1?.map((item, index) =>
                    <SubTopicColumn
                        key={item?.id}
                        nodeRef={nodeRef}
                        item={item}
                        activeItem={activeItem}
                        productName={productName}
                        isLast={column1?.length === index + 1} />)}
            </div> : null}
        {column2?.length > 0 ?
            <div className={classes.Column}>
                {column2?.map((item, index) =>
                    <SubTopicColumn
                        key={item?.id}
                        nodeRef={nodeRef}
                        item={item}
                        activeItem={activeItem}
                        productName={productName}
                        isLast={column2?.length === index + 1} />)}
            </div> : null}
    </DataValidator>
);

export default SubTopicColumns;
