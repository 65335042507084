import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {useLocation} from 'react-router';
import SearchStore from 'js/store/SearchStore';
import SeoHelmet from 'js/containers/SEO/SeoHelmet';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';
import Heading from 'js/components/Common/Heading/Heading';
import Container from 'js/components/Common/Container/Container';
import TopSection from 'js/components/Common/TopSection/TopSection';
import { CommonContext } from 'js/context/CommonContext/CommonContext';
import subjectMatter from 'js/containers/Taxonomies/SubjectMatter/SubjectMatter';
import SearchResults from 'js/containers/Pages/Search/SearchResults/SearchResults';
import SearchSidebar from 'js/containers/Pages/Search/SearchSidebar/SearchSidebar';

import classes from './Search.module.pcss';
import filterFetchData from './SearchFilter/fetchData';
import videoFetchData from './SearchResults/Entities/Entity/Video/VideoFetchData';
import manualFetchData from './SearchResults/Entities/Entity/Manual/ManualFetchData';
import courseFetchData from './SearchResults/Entities/Entity/Course/CourseFetchData';
import articleFetchData from './SearchResults/Entities/Entity/Article/ArticleFetchData';
import searchResultCountData from './SearchResults/SearchResultsCount/SearchResultsCountData';

const Search = () => {
    const { pathname } = useLocation();
    const nodeRef = useRef(null);
    const scrollMidRef = useRef(null);
    const scrollEndRef = useRef(null);
    const [searchData, setSearchData] = useState([]);
    const [pagesCount, setPagesCount] = useState<number>(0);
    const [postsCount, setPostsCount] = useState<number>(0);
    const [load, setLoad] = useState<boolean>(false);

    const {
        searchQuery,
        filterTaxData,
        filterEntitiesData,
        orderBy,
        searchStatus,
        searchPage
    } = SearchStore;

    const {apiPath} = useContext(CommonContext);

    const {INIT_MIX_PANEL} = useMemo(() => MixPanelWrapper, []);

    useEffect(() => {
        (async () => {
            await MixPanelWrapper.TrackPageView({
                page: 'Search'
            });
        })();
    }, [INIT_MIX_PANEL]);

    const pageChangeHandler = useCallback((page) => {
        SearchStore.setSearchPage(page);
        SearchStore.setSearchStatus(false);
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, searchPage]);

    useEffect(() => {
        if (!load) {
            return;
        }
        (async () => {
            await MixPanelWrapper.TrackEvent(
                'Search Results count',
                {
                    category: 'Search Results',
                    value: `${postsCount}`
                });
        })();
    }, [load, postsCount]);

    useEffect(() => {
        let isCanceled = false;
        if (!searchQuery || searchStatus) {
            return;
        }
        const dotsReg = new RegExp('[.\\,\\_]+', 'g');
        const bracketsReg = new RegExp('[^ \\w]+', 'g');
        let filteredQuery = searchQuery.replace(dotsReg, '');
        filteredQuery = filteredQuery.replace(bracketsReg, ' ');

        (async () => {
            const response = await fetch(`${apiPath}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query: `
					{
                        search {
                          ${filterFetchData(filterEntitiesData, filterTaxData)}
                          searchResult(query: "${filteredQuery}", orderBy: "${orderBy}", page: ${searchPage}) {
                            ${courseFetchData}
                            ${articleFetchData}
                            ${manualFetchData}
                            ${videoFetchData}
                          }
                          ${searchResultCountData}
                        }
                    }
				`
                })
            });
            if (isCanceled) {
                return;
            }
            const { data } = await response.json();
            setSearchData(data?.search?.searchResult);
            setPagesCount(data?.search?.pagesCount);
            setPostsCount(data?.search?.postsCount);
            SearchStore.setEntitiesCounts(data?.search?.entitiesCount);

            SearchStore.setTaxCounts({
                suitableFor: data?.search?.suitableForCount,
                skills: data?.search?.skillsCount,
                productLegacy: data?.search?.productCount,
                subjectMatter: data?.search?.subjectMatterCount,
                language: data?.search?.languageCount,
                articleType: data?.search?.articleTypeCount,
                courseType: data?.search?.courseTypeCount,
                videoType: data?.search?.videoTypeCount,
                manualsType: data?.search?.manualTypeCount
            });

            SearchStore.setSearchStatus(true);
            setLoad(true);
            scrollMidRef.current = false;
            scrollEndRef.current = false;
            SearchStore.setIsSearchTransferData(false);
        })();
        return () => {
            isCanceled = true;
        };
    }, [
        searchStatus,
        searchQuery,
        apiPath,
        searchPage,
        filterTaxData,
        filterEntitiesData,
        orderBy
    ]);

    const setFilterSidebar = useMemo(() => {
        const searchQueryLength = searchQuery.length;
        const searchDataLength = searchData?.length;
        if (!searchQueryLength) {
            return null;
        }
        const {
            skills,
            suitableFor,
            videoType,
            manualsType,
            articleType,
            courseType,
            productLegacy,
            language
        } = filterTaxData || {};

        const isFilterChecked:boolean =
            !! filterEntitiesData?.length
            || !!skills?.length
            || !!suitableFor?.length
            || !!subjectMatter?.length
            || !!videoType?.length
            || !!manualsType?.length
            || !!articleType?.length
            || !!courseType?.length
            || !!productLegacy?.length
            || !!language?.length;

        if (searchQueryLength > 0 && searchDataLength === 0) {
            if (isFilterChecked) {
                return <SearchSidebar nodeRef={nodeRef}/>;
            }
            return null;
        }
        return <SearchSidebar nodeRef={nodeRef}/>;
    }, [
        filterEntitiesData,
        filterTaxData,
        searchData,
        searchQuery
    ]);

    const searchResults = useMemo(() => {
        const searchQueryLength = searchQuery.length;
        const searchDataLength = searchData?.length;

        return (
            <Container additionalClass={classes.SearchResultsWrap}>
                { setFilterSidebar }
                {(searchQueryLength > 0 && searchDataLength > 0) ? <SearchResults
                    data={searchData}
                    count={postsCount}
                    pages={pagesCount}
                    pageChange={pageChangeHandler}/> : searchStatus ?
                    <div className={classes.SearchResultsEmpty}>
                        <Heading text={`Sorry, there were no results for <strong>‘${searchQuery}’</strong>`} type={'h2'} />
                        <Heading text={'Check your spelling or try a more general term.'} type={'h5'} />
                    </div> : null
                }
            </Container>
        );
    }, [searchStatus, setFilterSidebar, pageChangeHandler, pagesCount, postsCount, searchData, searchQuery]);

    return (
        <div id={'Search'} className={classes.Search} ref={nodeRef}>
            <SeoHelmet title={'Search'} description={'Search'} canonical={'/search'}/>
            <TopSection pageId={null} pageType={'search'} />
            {searchResults}
        </div>
    );
};

export default observer(Search);
