import {PageType} from './TopSection';

export type FetchResultType = {
    title: string,
    parent: {
        uri: string,
        name?: string,
        title?: string
    },
    template?: {
        templateName: string
    }
}
const productFetchData = (pageId: any):string => `
{
    productLegacy(id: "${pageId}", idType: DATABASE_ID) {
        name
        parent {
            uri
            name
      }
    }
  }
`;

const pageFetchData = (pageId: any):string => `
{
    page(id: "${pageId}", idType: DATABASE_ID) {
        title
        template {
        ... on DefaultTemplate {
          templateName
        }
        ... on AboutUsTemplate {
          templateName
        }
        ... on ContactUsTemplate {
          templateName
        }
        ... on CoursesCatalogueTemplate {
          templateName
        }
        ... on CoursesTemplate {
          templateName
        }
        ... on EventsTemplate {
          templateName
        }
        ... on HelpTemplate {
          templateName
        }
        ... on ManualsHubTemplate {
          templateName
        }
        ... on OnboardingTemplate {
          templateName
        }
        ... on PolicyTemplate {
          templateName
        }
        ... on PostLoginPageTemplate {
          templateName
        }
        ... on PreLoginPageTemplate {
          templateName
        }
        ... on TrainingTemplate {
          templateName
        }
        }
    }
  }
`;

export const getFetchResult = (data: any, type: PageType): FetchResultType => {
    switch (type) {
        case 'product':  {
            const {name, parent} = data?.productLegacy;
            return {
                title: name,
                parent
            };
        }
        case 'page':  {
            const {title} = data?.page;
            const {template} = data?.page;
            return {
                title,
                template,
                parent: null
            };
        }
        default: return {
            title: '',
            parent: null
        };
    }
};

export const fetchData = (type: PageType, pageId: number):string => {
    switch (type) {
        case 'product':
            return productFetchData(pageId);
        case 'page':
            return pageFetchData(pageId);
        default:
            return '';
    }
};
export default fetchData;
