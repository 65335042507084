import React, {useCallback} from 'react';
import NavigationItem from 'js/components/Common/Navigation/NavigationItem/NavigationItem';

import { NavigationMenuItemType } from '../NavigationMenu/NavigationMenuDataInterface';

export interface Props {
	menuItems: Array<NavigationMenuItemType>,
	isSubMenu: boolean,
	location: string
}

const NavigationItems = ({ location, menuItems, isSubMenu }:Props) => {
	const setSubMenu = useCallback((menuItem) => {
		const isSubMenuCondition = menuItem?.childItems && menuItem?.childItems?.nodes?.length > 0;
		if (!isSubMenuCondition) {
			return '';
		}
		return (
			<NavigationItems
				location={location}
				menuItems={menuItem.childItems.nodes}
				isSubMenu={isSubMenuCondition}/>
		);
	}, [location]);

	return (
			<ul className={isSubMenu ? 'SubMenu' : ''}>
				{
					menuItems?.map((menuItem) => {
						let isSubMenuClass = false;
						if (menuItem?.childItems?.nodes?.length > 0) {
							isSubMenuClass = true;
						}
						return (
							<NavigationItem
								location={location}
								key={parseInt(menuItem.id)}
								item={menuItem}
								hasSubMenuClass={isSubMenuClass}
								subMenu={setSubMenu(menuItem)}>
								{menuItem.label}
							</NavigationItem>
						);
					}
				)
			}
		</ul>
	);
};

export default NavigationItems;
