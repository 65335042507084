import React, {useCallback, useMemo, useState} from 'react';
import Heading from 'js/components/Common/Heading/Heading';
import useFetchData from 'js/hooks/useFetchData/useFetchData';
import Container from 'js/components/Common/Container/Container';
import DataValidator from 'js/components/Common/DataValidator/DataValidator';
import {ImageBlockDataInterface} from 'js/components/Common/Image/ImageBlockDataInterface';
import parse from 'html-react-parser';

import fetchData from './fetchData';
import classes from './Offerings.module.pcss';
import OfferingsService from './OfferingsService';
import OfferingsTabs from './OfferingsTabs/OfferingsTabs';
import OfferingsCourse from './OfferingsCourse/OfferingsCourse';
import MixPanelWrapper from 'js/servises/MixPanelWrapper';

interface Props {
    pageId: number
}

export type ProductType = {
    id: string,
    inThisTraining: {
        item: string
    }[],
    aboutThisTraining:string,
    productName:string,
    videoButton: {
        videoLink: string,
        buttonLabel: string,
        videoPreview: ImageBlockDataInterface
    }
}

export type CourseType = {
    id: number,
    courseTitle: string,
    productsTab: Array<ProductType>
}

const Offerings = ({pageId}: Props) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [courses, setCourses] = useState<Array<CourseType>>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [activeProductTab, setActiveProductTab] = useState<string>('0_0');

    const {isLoading, error} = useFetchData({
        query: `${fetchData(pageId)}`,
        condition: !!pageId,
        updateData: (data: any): void => {
            const result = data?.page?.TrainingTemplate;
            setTitle(result?.titleOffer);
            setDescription(result?.descriptionOffer);
            setCourses(result?.offerings);
        }
    });

    const {column1, column2} = useMemo(() =>
        OfferingsService.separateCoursesColumns(courses), [courses]);

    const activeCourse:CourseType = useMemo(() =>
        OfferingsService.getActiveCourse(activeTab, courses), [activeTab, courses]);

    const courseTabEventHandler = useCallback(async (column, id) => {
        await MixPanelWrapper.TrackEvent(
            'Course button is clicked on',
            {
                value: `${column?.find(item => item?.id === id)?.courseTitle}`,
                category: 'Training page'
            });
    }, []);

    const productTabEventHandler = useCallback(async (id) => {
        await MixPanelWrapper.TrackEvent(
            'Product button is clicked on',
            {
                courseName: `${activeCourse?.courseTitle}`,
                productName: `${activeCourse?.productsTab?.find(item => item?.id === id)?.productName}`,
                category: 'Training page'
            });
    }, [activeCourse]);

    return (
        <DataValidator data={!isLoading && !error}>
            <section className={classes.Offerings}>
                <Container>
                    <Heading text={title} />
                    <div className={classes.Description}>{parse(description || '')}</div>
                    <div className={classes.Wrapper}>
                        <OfferingsTabs
                            tabs={column1}
                            activeTab={activeTab}
                            tabClickHandler={async (id:number) => {
                                setActiveTab(id);
                                setActiveProductTab(`${id}_0`);
                                await courseTabEventHandler(column1, id);
                            }}  />
                        <OfferingsCourse
                            activeCourse={activeCourse}
                            activeProductTab={activeProductTab}
                            tabClickHandler={async (id:string) => {
                                setActiveProductTab(id);
                                await productTabEventHandler(id);
                            }}
                        />
                        <OfferingsTabs
                            tabs={column2}
                            activeTab={activeTab}
                            tabClickHandler={async (id:number) => {
                                setActiveTab(id);
                                setActiveProductTab(`${id}_0`);
                                await courseTabEventHandler(column2, id);
                            }} />
                    </div>
                </Container>
            </section>
        </DataValidator>
    );
};

export default Offerings;
